import React from 'react';
import HeroSplit from '../components/sections/HeroSplit';
import Clients from '../components/sections/Clients';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import Pricing from '../components/sections/Pricing';
import Cta from '../components/sections/Cta';
import Image from "../components/elements/Image";
import Input from "../components/elements/Input";
import Button from "../components/elements/Button";
import Modal from "../components/elements/Modal";

import {db} from "../utils/firebase"
import { collection, addDoc, serverTimestamp } from "firebase/firestore"

class Home extends React.Component {

    state = {
        demoModalActive: false,
        email: ""
    }

    openModal = (e) => {
        e.preventDefault();
        this.setState({
            demoModalActive: true,
            email: ""
        });
    }

    closeModal = (e) => {
        e.preventDefault();
        this.setState({
            demoModalActive: false,
            email: ""
        });
    }

    handleChange = (e) => {
        e.preventDefault()
        this.setState({
            demoModalActive: true,
            email: e.target.value
        })
    }

    handleEmail = async (e) => {
        e.preventDefault();
        try {
            const docRef = await addDoc(collection(db, "mailinglist"), {
                email: this.state.email,
                timestamp: serverTimestamp()
            });
            console.log("Success!: ", docRef.id)
        } catch (e) {
            console.log(e)
        } finally {
            this.setState({
                demoModalActive: false,
                email: ""
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                <HeroSplit imageFill className="illustration-section-01" bottomDivider />
                {/** <Clients topDivider bottomDivider /> **/}
                <FeaturesSplit invertMobile imageFill className="illustration-section-03" />
                <FeaturesTiles className="gradient-section" />
                {/**<Pricing hasBgColor topDivider pricingSwitcher /> **/}
                <Cta
                    hasBgColor
                    invertColor
                    split
                    className="illustration-element-08"
                    openModal={this.openModal}
                />
                <Modal
                    id="demo-modal"
                    show={this.state.demoModalActive}
                    handleClose={this.closeModal}
                >
                    <div className="center-content">
                        <div className="features-split-icon mb-16">
                            <Image
                                src={require('../assets/images/frame-icon.svg')}
                                alt="Frame icon"
                                width={64}
                                height={64} />
                        </div>
                        <h3 className="mt-0 mb-12">Warteliste beitreten</h3>
                        <p className="text-sm">
                            Wir sind auf der Suche nach weiteren Betatestern. Tragen Sie sich bitte in den E-Mail Verteiler ein, und Sie werden bald von uns hören!
                        </p>
                    </div>
                    <Input
                        type="email"
                        label="This is a label"
                        placeholder="Ihre E-Mail.."
                        formGroup="desktop"
                        value={this.state.email}
                        onChange={this.handleChange}
                        labelHidden>
                        <Button
                            color="primary"
                            onClick={this.handleEmail}
                        >Eintragen</Button>
                    </Input>
                    <div className="center-content mt-24">
                        <a
                            className="func-link text-xxs fw-500 tt-u"
                            aria-label="close"
                            href="#"
                            onClick={this.closeModal}
                        >Nein danke!</a>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

export default Home;