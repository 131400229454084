import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import Image from '../elements/Image';
import { Link } from 'react-router-dom';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class HeroSplit extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'hero section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'hero-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <div className={splitClasses}>
              <div className="split-item">
                <div className="hero-content split-item-content center-content-mobile">
                  <h1 className="mt-0 mb-16 reveal-from-left">
                    Haleos SMT
                  </h1>
                  <p className="mt-0 mb-32 reveal-from-left" data-reveal-delay="200">
                    Mit dem SMT Billboard Stick kann in 5 Minuten ein Bildschirm oder Fernseher zu einem digitalen Display umfunktioniert werden.
                    Mit intuitiver Software lassen sich Sequenzen einfach Anlegen und jederzeit, auch unterwegs, bearbeiten.
                    Es ist kinderleicht die Sticks zu gruppieren, Sequenzen dynamsich zu aktualisieren und die eigenen Datenquellen anzubinden.
                  </p>
                  <Link
                      to="/jobs/"
                      className="button button-primary button-wide-mobile button-sm"
                      onClick={this.closeMenu}
                  >Investor? Gespräch vereinbaren</Link>
                </div>
                <div className="hero-figure is-3d split-item-image illustration-element-01 reveal-from-right">
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/hero-image.png')}
                    alt="Hero"
                    width={528}
                    height={396} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

HeroSplit.propTypes = propTypes;
HeroSplit.defaultProps = defaultProps;

export default HeroSplit;