import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class FeaturesTiles extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
        'features-tiles section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'features-tiles-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
        'tiles-wrap',
        pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'Maßgeschneiderte Funktionen',
      paragraph: 'Alles, was ein modulbasiertes System benötigt - in ständiger Entwicklung versorgen wir das System immer mit aktuellen Sicherheitsupdates und neuen Funktionen.'
    };

    return (
        <section
            {...props}
            className={outerClasses}
        >
          <div className="container">
            <div className={innerClasses}>
              <SectionHeader data={sectionHeader} className="center-content" />
              <div className={tilesClasses}>

                <div className="tiles-item">
                  <div className="tiles-item-inner">
                    <div className="features-tiles-item-header">
                      <div className="features-tiles-item-image mb-16 reveal-from-top" data-reveal-container=".tiles-item">
                        <Image
                            src={require('./../../assets/images/feature-tile-icon-01.svg')}
                            alt="Features tile icon 01"
                            width={72}
                            height={72} />
                      </div>
                    </div>
                    <div className="features-tiles-item-content">
                      <h4 className="h5 mt-0 mb-8 reveal-from-top" data-reveal-container=".tiles-item" data-reveal-delay="100">
                        Responsive
                      </h4>
                      <p className="m-0 text-sm reveal-from-top" data-reveal-container=".tiles-item" data-reveal-delay="200">
                        Die Ansichten lassen sich responsiv konfigurieren und sehen auf den meisten Geräten ohne zusätzliche Konfiguration gut aus.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="tiles-item">
                  <div className="tiles-item-inner">
                    <div className="features-tiles-item-header">
                      <div className="features-tiles-item-image mb-16 reveal-from-top" data-reveal-container=".tiles-item" data-reveal-delay="100">
                        <Image
                            src={require('./../../assets/images/feature-tile-icon-02.svg')}
                            alt="Features tile icon 02"
                            width={72}
                            height={72} />
                      </div>
                    </div>
                    <div className="features-tiles-item-content">
                      <h4 className="h5 mt-0 mb-8 reveal-from-top" data-reveal-container=".tiles-item" data-reveal-delay="200">
                        Zeit- und Raumpläne
                      </h4>
                      <p className="m-0 text-sm reveal-from-top" data-reveal-container=".tiles-item" data-reveal-delay="300">
                        Die Geräte lassen sich in Gruppen einteilen - das ist übersichtlich und erleichtert die Bedienung. Neue Geräte integrieren sich von alleine.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="tiles-item">
                  <div className="tiles-item-inner">
                    <div className="features-tiles-item-header">
                      <div className="features-tiles-item-image mb-16 reveal-from-top" data-reveal-container=".tiles-item" data-reveal-delay="200">
                        <Image
                            src={require('./../../assets/images/feature-tile-icon-03.svg')}
                            alt="Features tile icon 03"
                            width={72}
                            height={72} />
                      </div>
                    </div>
                    <div className="features-tiles-item-content">
                      <h4 className="h5 mt-0 mb-8 reveal-from-top" data-reveal-container=".tiles-item" data-reveal-delay="300">
                        User Management System
                      </h4>
                      <p className="m-0 text-sm reveal-from-top" data-reveal-container=".tiles-item" data-reveal-delay="400">
                        Verschiedene Nutzer mit unterschiedlichen Berechtigungen lassen sich ganz einfach erstellen und verwalten.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="tiles-item">
                  <div className="tiles-item-inner">
                    <div className="features-tiles-item-header">
                      <div className="features-tiles-item-image mb-16 reveal-from-top" data-reveal-container=".tiles-item">
                        <Image
                            src={require('./../../assets/images/feature-tile-icon-04.svg')}
                            alt="Features tile icon 04"
                            width={72}
                            height={72} />
                      </div>
                    </div>
                    <div className="features-tiles-item-content">
                      <h4 className="h5 mt-0 mb-8 reveal-from-top" data-reveal-container=".tiles-item" data-reveal-delay="100">
                        Cloudbasiert
                      </h4>
                      <p className="m-0 text-sm reveal-from-top" data-reveal-container=".tiles-item" data-reveal-delay="200">
                        Die Daten werden nach Industriestandarts auf deutschen Servern gespeichert und sind jederzeit verfügbar.
                      </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
    );
  }
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;