import {initializeApp} from "firebase/app"
import {getFirestore} from "firebase/firestore"

const firebaseApp = initializeApp({

    apiKey: "AIzaSyALQUyKbK238l7YOmCoPQoAh_6BZR47pzs",

    authDomain: "haleos.firebaseapp.com",

    databaseURL: "https://haleos.firebaseio.com",

    projectId: "haleos",

    storageBucket: "haleos.appspot.com",

    messagingSenderId: "998416379941",

    appId: "1:998416379941:web:cdb78dd62b37d1b970cfcc"

});

const db = getFirestore()
export {db}