import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import Image from "../elements/Image";
import Input from "../elements/Input";
import Modal from "../elements/Modal";

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

class Cta extends React.Component {

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      split,
      ...props
    } = this.props;

    const outerClasses = classNames(
        'cta section center-content-mobile',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'cta-inner section-inner reveal-from-top',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider',
        split && 'cta-split'
    );

    return (
        <section
            {...props}
            className={outerClasses}
        >
          <div className="container">
            <div
                className={innerClasses}
            >
              <div className="cta-slogan">
                <h3 className="m-0">
                  Interessiert? Jetzt Betatester werden!
                </h3>
              </div>
              <div className="cta-action">
                <Button onClick={this.props.openModal} color="secondary" wideMobile href="#0">
                  E-Mail eintragen
                </Button>
              </div>
            </div>
          </div>
        </section>
    );
  }
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;